export interface AnnouncementConfirmationInput {
  notes: string;
  identifier: string;
}

export interface AnnouncementCodeRetrievalInput {
  announcementCode: string;
  type?: CodeType;
}

export interface AppointmentDetails {
  date: Date;
  numberOfObjects: number;
  address: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  inspectorName: string;
  objectTypeSummaries: string[];
}

export interface OFAnnouncementDataOutput {
  data: {
    periodType: 0 | 1;
    period: periodTypes;
    isCompleted: boolean;
    maintenanceFirmName: string;
    hasUnmarkedItems: boolean;
    maintenanceFirmURL: string;
    isFirstLoad: boolean;
    hasMissingInspectorInformation: boolean;
    maintenanceFirmAnnouncementId: string;
    weeks: {
      weekNumber: number;
      days: {
        inspector: string;
        date: string;
        inspections: Inspection[];
      }[];
    }[];
  };
}

export interface AnnouncementOFOverviewHeaderData {
  data: { name: string; periodType: periodTypes; identifier: string };
}

export interface SearchAnnounceOFOverviewDetailsInput {
  identifier: string;
  showCompleted: boolean;
}

export interface AnnouncementOFOverviewDetailsData {
  messages: string[];
  succeeded: boolean;
  data: {
    identifier: string;
    period: string;
    lastModificationChex: string | Date;
    lastSaveTime: string | Date;
    numberOfInspectionsToReview: number;
    totalNumberOfInspections: number;
    hasCompletedDaysWithoutMechanic: boolean;
  }[];
}

export interface Inspection {
  id: number;
  historyItemId?: number;
  status: statusTypes;
  comment: string;
  commentChex: string;
  contract: string;
  startTime: Date;
  endTime: Date;
  inspectorName: string;
  objectId: number;
  objectAddress: string;
  objectPostalcode: string;
  objectCity: string;
  objectInstallationNumber: string;
  objectAndInspectionType: string;
  // objectSONumber: string;
  mechanic: string;
  changeType: changeTypes;
  history?: InspectionHistoryItem[];
  isLocked: boolean;
  mechanicPhoneNumber: string;
  alternativeDates?: Date[];
  agendaItemPlanningId: number;
  isProcessedByOF: boolean;
  isHistory: boolean;
  internalNumber: string;
  orderNumber: string;
  alternativeDatesText?: string;
  isModificationInspection: boolean;
  showModificationInspectionTime: boolean;
}

export interface InspectionHistoryItem {
  id: number;
  creationTime: Date;
  startTime: Date;
  status?: statusTypes;
  endTime: Date;
  comment: string;
  commentChex: string;
  inspectorId: number;
  inspectorName: string;
  alternativeDatesText?: string;
}

export enum changeTypes {
  New = 0,
  Modified = 1,
  Deleted = 2,
}

export enum statusTypes {
  Reset = 0,
  Akkoord = 1,
  "Niet akkoord" = 2,
  "On-hold" = 3,
}

export enum periodTypes {
  Maandoverzicht = 0,
  Weekoverzicht = 1,
}

export interface RadioButtonGroupItem {
  name: string;
  value: number;
}

export interface AnnouncementRowIV {
  id: number;
  historyItemId?: number;
  comment: string;
  status?: statusTypes;
  mechanic: string;
  mechanicPhoneNumber: string;
  alternativeDates?: Date[];
  isProcessedByOF: boolean;
  internalNumber: string;
  orderNumber: string;
  changeType?: changeTypes;
}

export interface UpdateOFAnnouncement {
  identifier: string;
  inspections?: {
    id: number;
    comment: string;
    status?: number;
    mechanicPhoneNumber?: string;
    mechanic: string;
    alternativeDates?: Date[];
  }[];
}

export enum CodeType {
  customer = 1,
  maintenanceFirm = 2,
}

export interface getMechanicPhoneNumber {
  identifier: string;
  mechanicName: string;
}
