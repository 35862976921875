import RadioButtonInputGroup from "../../components/forms/RadioButtonGroupInput";
import { statusTypes } from "../../types";
import { getStatusColorByStatus } from "../../utility/statusColors";

interface StatusElementProps {
  name: string;
  disabled?: boolean;
  hideReset?: boolean;
  isModificationInspection?: boolean;
  callback?: () => void;
}

const StatusElement = ({
  name,
  disabled,
  hideReset,
  isModificationInspection,
  callback,
}: StatusElementProps) => {
  const statuses = [
    { name: "Akkoord", value: statusTypes.Akkoord },
    { name: "Niet akkoord", value: statusTypes["Niet akkoord"] },
    { name: "On-hold", value: statusTypes["On-hold"] },
  ];

  if (!hideReset) statuses.unshift({ name: "Reset", value: 0 });

  return (
    <>
      {isModificationInspection ? (
        <strong>Modificatiekeuring</strong>
      ) : (
        <RadioButtonInputGroup
          disabled={disabled}
          getItemColor={getStatusColorByStatus}
          identifier={name}
          radioOptions={statuses}
          callback={callback}
        />
      )}
    </>
  );
};

export default StatusElement;
